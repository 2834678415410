import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledWelcomeCard,
  StyledWelcomeCardInfo,
  StyledWelcomeImg,
} from './index.styled';

import './index.scss';

const WelcomeCard = ({data}) => {
  return <StyledWelcomeCard className='welcome_card'></StyledWelcomeCard>;
};

export default WelcomeCard;

WelcomeCard.defaultProps = {
  data: [],
};

WelcomeCard.propTypes = {
  data: PropTypes.array,
};
