import React from 'react';
import PropTypes from 'prop-types';

import {useIntl} from 'react-intl';
import AppRowContainer from '@crema/components/AppRowContainer';
import {Avatar, Col} from 'antd';
import AppList from '@crema/components/AppList';
import {
  StyledSalesStateCard,
  StyledSalesStateContent,
  StyledSalesStateItem,
  StyledSalesStateItemContent,
  StyledSalesStateMain,
} from './index.styled';
import './index.scss';

const TodaysPaymentCard = ({salesState, saleChartData}) => {
  const handleSelectionType = (data) => {
    console.log('data: ', data);
  };
  const {messages} = useIntl();
  return (
    <StyledSalesStateCard title={"Today's Payment"} heightFull>
      {/* <StyledSalesStateMain style={{ marginTop: '20px' }}>
        <AppRowContainer>
          <Col xs={24} sm={24} className='mb-0'>
            <StyledSalesStateContent>
              <AppList
                data={salesState}
                renderItem={(item) => (
                  <StyledSalesStateItem key={'salesState-' + item.id}>
                    <Avatar src={item.icon} alt='icon' />

                    <StyledSalesStateItemContent>
                      <h3>&#8377;{item.amount}</h3>
                      <p className='mb-0'>{item.type}</p>
                    </StyledSalesStateItemContent>
                  </StyledSalesStateItem>
                )}
              />
            </StyledSalesStateContent>
          </Col>
        </AppRowContainer>
      </StyledSalesStateMain> */}
      <div className='payment_card_wrapper' style={{marginTop: '20px'}}>
        <div className='card_header'>
          <div className='total'>Total : 560,000</div>
        </div>
        <div className='card_info'>
          <div className='left'>
            <div className='payment_item'>
              <div className='payment_value'>240,000</div>
              <div className='payment_type'>Cash</div>
            </div>
            <div className='payment_item'>
              <div className='payment_value'>100,000</div>
              <div className='payment_type'>In hand</div>
            </div>
            <div className='payment_item'>
              <div className='payment_value'>140,000</div>
              <div className='payment_type'>Submitted</div>
            </div>
          </div>
          <div className='right'>
            <div className='payment_item'>
              <div className='payment_value'>220,000</div>
              <div className='payment_type'>Cheque</div>
            </div>
            <div className='payment_item'>
              <div className='payment_value'>60,000</div>
              <div className='payment_type'>Online</div>
            </div>
            <div className='payment_item'>
              <div className='payment_value'>40,000</div>
              <div className='payment_type'>Online Pending</div>
            </div>
          </div>
        </div>
      </div>
    </StyledSalesStateCard>
  );
};
export default TodaysPaymentCard;

TodaysPaymentCard.defaultProps = {
  salesState: [],
  saleChartData: [],
};

TodaysPaymentCard.propTypes = {
  salesState: PropTypes.array,
  saleChartData: PropTypes.array,
};
