import React from 'react';
import {RoutePermittedRole} from '@crema/constants/AppEnums';

const PageDashboard = React.lazy(() =>
  import('../../../modules/accountantPages/dashboard'),
);

const PageAllReceivedPayment = React.lazy(() =>
  import('../../../modules/accountantPages/page_view_all_received_payment'),
);

export const accountantSidebarPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.User,
    path: '/accountant/dashboard',
    element: <PageDashboard />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/accountant/all-received-payment',
    element: <PageAllReceivedPayment />,
  },
];
