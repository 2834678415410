// ForJWT Auth
import {getUserFromJwtAuth} from '../helpers/AuthHelper';
import * as jose from 'jose';

const decodeToken = () => {
  const token = localStorage.getItem('token');

  if (!token) {
    return {
      user: null,
      isAuthenticated: false,
      isLoading: false,
    };
  }

  try {
    const decodedToken = jose.decodeJwt(token);
    // Use the decoded token as needed
    return {
      user: decodedToken,
      isAuthenticated: true,
      isLoading: true,
    };
  } catch (error) {
    console.error('Failed to decode token:', error);
    return {
      user: null,
      isAuthenticated: false,
      isLoading: false,
    };
  }
};

export const useAuthUser = () => {
  const {user, isAuthenticated, isLoading} = decodeToken();
  return {
    isLoading,
    isAuthenticated,
    user: getUserFromJwtAuth(user),
  };
};

export const logout = () => {
  localStorage.removeItem('token');

  //redirect to login page
  window.location.href = '/signin';

  return {
    user: null,
    isLoading: false,
    isAuthenticated: false,
  };
};

export const useAuthMethod = () => {
  return {
    logout,
  };
};
