import React from 'react';
import {RoutePermittedRole} from '@crema/constants/AppEnums';
import Account from '../../../modules/account/MyProfile';
import DashboardPage from '../../../modules/demo/dashboard';

const PageDashboard = React.lazy(() =>
  import('../../../modules/ticketAgentPages/dashboard'),
);

const PageViewAllPassenger = React.lazy(() =>
  import('../../../modules/ticketAgentPages/view_passenger'),
);

export const ticketAgentSidebarPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.User,
    path: '/ticket-agent/dashboard',
    element: <PageDashboard />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/ticket-agent/view-all-passenger',
    element: <PageViewAllPassenger />,
  },
];
