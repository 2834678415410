import React from 'react';
import {RoutePermittedRole} from '@crema/constants/AppEnums';
import Account from '../../../modules/account/MyProfile';
import DashboardPage from '../../../modules/demo/dashboard';

export const accountPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.User,
    path: '/my-profile',
    element: <Account />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/dashboard',
    element: <DashboardPage />,
  },
];
