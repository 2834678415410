import React from 'react';

import AppCard from '@crema/components/AppCard';
import AppRowContainer from '@crema/components/AppRowContainer';
import {Col} from 'antd';
import {useGetDataApi} from '@crema/hooks/APIHooks';
import AppLoader from '@crema/components/AppLoader';
import AppAnimate from '@crema/components/AppAnimate';

// WelcomeCard
import WelcomeCard from '../../../components/dashboard/WelcomeCard';
import StatsItemCard from '../../../components/dashboard/StatsItemCard';
import EnquiryBookingChart from '../../../components/dashboard/EnquiryBookingChart';
import TodaysPaymentCard from '../../../components/dashboard/TodaysPaymentCard';
import SPMonthlyStatsCard from '../../../components/dashboard/SPMonthlyStatsCard';
import './index.scss';
const DashboardPage = () => {
  const [{apiData: analyticsData, loading}] = useGetDataApi(
    '/dashboard/analytics',
  );
  const [{apiData: crmData, loading2}] = useGetDataApi('/dashboard/crm');

  const metricsData = {
    metricsStats: [
      {
        id: 1,
        title: 'follow-up',
        done: '10',
        pending: '7',
        total: '17',
        badgeColor: 'rgba(249, 145, 28, 0.2)',
        bgcolor: '#E7F4FB',
        icon: 'tour/dashboard/follow_up.png',
        link: '/sales/follow-up',
      },
      {
        id: 2,
        title: 'Handover',
        done: '10',
        pending: '7',
        total: '17',
        badgeColor: 'rgba(255, 230, 230, 1)',
        bgcolor: '#E7F4FB',
        icon: 'tour/dashboard/handover.png',
        link: '',
      },
      {
        id: 3,
        title: 'CEO asgmt',
        done: '10',
        pending: '7',
        total: '17',
        badgeColor: 'rgba(216, 222, 255, 1)',
        bgcolor: '#E7F4FB',
        icon: 'tour/dashboard/assigned_task.png',
        link: '/',
      },
      {
        id: 4,
        title: 'Enquiry',
        done: '10',
        pending: '7',
        total: '17',
        badgeColor: 'rgba(204, 239, 241, 1)',
        bgcolor: '#E7F4FB',
        icon: 'tour/dashboard/enquiry.png',
        link: '/sales/view-all-enquiry',
      },
      {
        id: 5,
        title: 'Booking',
        done: '10',
        pending: '7',
        total: '17',
        badgeColor: 'rgba(216, 222, 255, 1)',
        bgcolor: '#E7F4FB',
        icon: 'tour/dashboard/booking.png',
        link: '/sales/view-all-booking',
      },
      {
        id: 6,
        title: 'Add task',
        done: '10',
        pending: '7',
        total: '17',
        badgeColor: 'rgba(255, 230, 230, 1)',
        bgcolor: '#E7F4FB',
        icon: 'tour/dashboard/additional_task.png',
        link: '/',
      },
      {
        id: 7,
        title: 'Feedback ',
        done: '10',
        pending: '7',
        total: '17',
        badgeColor: 'rgba(249, 145, 28, 0.2)',
        bgcolor: '#E7F4FB',
        icon: 'tour/dashboard/feedback.png',
        link: '/',
      },
      {
        id: 8,
        title: 'Payment  ',
        done: '10',
        pending: '7',
        total: '17',
        badgeColor: 'rgba(204, 239, 241, 1)',
        bgcolor: '#E7F4FB',
        icon: 'tour/dashboard/call_for_payment.png',
        link: '/sales/view-all-received-payment',
      },
      {
        id: 9,
        title: 'Mgr asgmt',
        done: '10',
        pending: '7',
        total: '17',
        badgeColor: 'rgba(216, 222, 255, 1)',
        bgcolor: '#E7F4FB',
        icon: 'tour/dashboard/manager_task.png',
        link: '/',
      },
    ],
  };

  const chartData = {
    visitorPageView: [
      {name: 'Jan', Booking: 150, Enquiries: 270},
      {name: 'Feb', Booking: 250, Enquiries: 200},
      {name: 'Mar', Booking: 180, Enquiries: 280},
      {name: 'Apr', Booking: 278, Enquiries: 250},
      {name: 'May', Booking: 250, Enquiries: 300},
      {name: 'Jun', Booking: 350, Enquiries: 250},
      {name: 'Jul', Booking: 280, Enquiries: 300},
      {name: 'Aug', Booking: 340, Enquiries: 240},
      {name: 'Sep', Booking: 280, Enquiries: 300},
      {name: 'Oct', Booking: 400, Enquiries: 270},
      {name: 'Nov', Booking: 250, Enquiries: 240},
      {name: 'Dec', Booking: 400, Enquiries: 270},
    ],
  };

  return loading ? (
    <AppLoader />
  ) : (
    <AppAnimate animation='transition.slideUpIn' delay={200}>
      <AppRowContainer
        interval={120}
        ease={'easeInSine'}
        className='page_dashboard'
      >
        <Col xs={24} lg={24} xl={24} className='mb-0' key={'a'}>
          <AppRowContainer interval={120}>
            <Col lg={24}>
              <AppRowContainer>
                {metricsData.metricsStats.map((item, index) => (
                  <Col xs={12} sm={12} md={8} lg={8} key={index}>
                    <StatsItemCard stats={item} />
                  </Col>
                ))}
              </AppRowContainer>
            </Col>
            {/* <Col lg={6}>
              <WelcomeCard data={analyticsData.welcomeCardCustom} />
            </Col> */}
          </AppRowContainer>
        </Col>

        <Col xs={24} lg={10} xxl={10} key={'f'}>
          <EnquiryBookingChart data={chartData.visitorPageView} />
        </Col>

        <Col xs={24} lg={6} key={'e'}>
          <TodaysPaymentCard
            salesState={analyticsData.salesStateCustom}
            saleChartData={analyticsData.salesChartData}
          />
        </Col>
        <Col xs={24} lg={6} key={'g'}>
          <SPMonthlyStatsCard
            salesState={analyticsData.salesStateCustom}
            saleChartData={analyticsData.salesChartData}
          />
        </Col>
      </AppRowContainer>
    </AppAnimate>
  );
};

export default DashboardPage;
