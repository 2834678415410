import React from 'react';
import PropTypes from 'prop-types';

import {useIntl} from 'react-intl';
import AppRowContainer from '@crema/components/AppRowContainer';
import {Avatar, Col} from 'antd';
import AppList from '@crema/components/AppList';
import {
  StyledSalesStateCard,
  StyledSalesStateContent,
  StyledSalesStateItem,
  StyledSalesStateItemContent,
  StyledSalesStateMain,
} from './index.styled';
import './index.scss';

const SPMonthlyStatsCard = ({salesState, saleChartData}) => {
  const handleSelectionType = (data) => {
    console.log('data: ', data);
  };
  const {messages} = useIntl();
  return (
    <StyledSalesStateCard title={'Monthly Enquiries/ Booking'} heightFull>
      {/* <StyledSalesStateMain style={{ marginTop: '20px' }}>
        <AppRowContainer>
          <Col xs={24} sm={24} className='mb-0'>
            <StyledSalesStateContent>
              <AppList
                data={salesState}
                renderItem={(item) => (
                  <StyledSalesStateItem key={'salesState-' + item.id}>
                    <Avatar src={item.icon} alt='icon' />

                    <StyledSalesStateItemContent>
                      <h3>&#8377;{item.amount}</h3>
                      <p className='mb-0'>{item.type}</p>
                    </StyledSalesStateItemContent>
                  </StyledSalesStateItem>
                )}
              />
            </StyledSalesStateContent>
          </Col>
        </AppRowContainer>
      </StyledSalesStateMain> */}
      <div className='monthly_card_wrapper'>
        <div className='card_info'>
          <div className='item'>
            <div className='user_name'>Sohail</div>
            <div className='btn_booking_or_enquiry_count'>100/70</div>
          </div>
          <div className='item'>
            <div className='user_name'>Amir</div>
            <div className='btn_booking_or_enquiry_count'>100/70</div>
          </div>
          <div className='item'>
            <div className='user_name'>Usman Khan</div>
            <div className='btn_booking_or_enquiry_count'>82/50</div>
          </div>
          <div className='item'>
            <div className='user_name'>Jane B. Rilea</div>
            <div className='btn_booking_or_enquiry_count'>55/40</div>
          </div>
        </div>
      </div>
    </StyledSalesStateCard>
  );
};
export default SPMonthlyStatsCard;

SPMonthlyStatsCard.defaultProps = {
  salesState: [],
  saleChartData: [],
};

SPMonthlyStatsCard.propTypes = {
  salesState: PropTypes.array,
  saleChartData: PropTypes.array,
};
