import React from 'react';
import AppCard from '@crema/components/AppCard';
import AppScrollbar from '@crema/components/AppScrollbar';
import {rgba} from 'polished';
import styled from 'styled-components';

export const StyledWelcomeCard = styled(AppCard)`
  overflow: hidden;
`;

export const StyledWelcomeCardInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${({theme}) => theme.breakpoints.sm}px) {
    flex-direction: row;
  }
`;

export const StyledWelcomeImg = styled.div`
  display: flex;
  align-items: flex-end;
  margin-right: -10px;
  margin-bottom: -20px;

  [dir='rtl'] & {
    margin-right: 0;
    margin-left: -10px;
  }

  @media screen and (min-width: ${({theme}) => theme.breakpoints.sm}px) {
    max-width: 250px;
    min-width: 140px;
  }

  @media screen and (min-width: ${({theme}) => theme.breakpoints.xxl}px) {
    max-width: 310px;
    min-width: 170px;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
