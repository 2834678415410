import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Checkbox, Form, Input, Button, message, Space} from 'antd';
import {useIntl} from 'react-intl';
import IntlMessages from '@crema/helpers/IntlMessages';
import {
  BACKEND_AUTH_ENDPOINT,
  BACKEND_API_ENDPOINT,
} from '../../../app/constants';

import {
  SignInButton,
  StyledRememberMe,
  StyledSign,
  StyledSignContent,
  StyledSignForm,
  StyledSignLink,
} from './index.styled';
import './index.scss';

const SignInCustom = () => {
  const [loadings, setLoadings] = useState([false, false, false]);

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;

      return newLoadings;
    });

    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;

        return newLoadings;
      });
    }, 6000);
  };

  const navigate = useNavigate();
  // const {logInWithEmailAndPassword, logInWithPopup} = useAuthMethod();
  //   const {signInUser, signUpUser, logout} = useAuthMethod();
  const {messages} = useIntl();

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const onGoToForgetPassword = () => {
    navigate('/forget-password', {tab: 'firebase'});
  };

  function onRememberMe(e) {
    console.log(`checked = ${e.target.checked}`);
  }

  const [isOtpEnabled, setIsOtpEnabled] = useState(0);
  const [isOtpButtonEnabled, setIsOtpButtonEnabled] = useState(1);

  const [isLoginButtonEnabled, setIsLoginButtonEnabled] = useState(0);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');

  useEffect(() => {
    if (email != '' && password != '') {
      setIsLoginButtonEnabled(1);
    } else {
      setIsLoginButtonEnabled(0);
    }
  }, [email, password]);

  const sendOTP = (e) => {
    e.preventDefault();
    if (email == '' || password == '') {
      messageAlert('error', 'Please enter email and password');
      return;
    }

    generateOTP(email, password);
  };

  const generateOTP = (email, password) => {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);

    enterLoading(0);
    fetch(`${BACKEND_AUTH_ENDPOINT}generate-otp`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 1) {
          messageAlert('success', 'OTP sent to your email');

          setIsOtpButtonEnabled(0);
          setIsOtpEnabled(1);
        } else {
          messageAlert('error', 'Invalid email or password');
          setIsOtpButtonEnabled(1);
          setIsOtpEnabled(0);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const signInUserSubmit = (values) => {
    //check email, password and otp must not be empty
    if (email == '' || password == '') {
      messageAlert('error', 'Please enter email and password');
      return;
    }

    if (isOtpEnabled == 1 && otp == '') {
      messageAlert('error', 'Please enter OTP');
      return;
    }

    //submit form
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    formData.append('otp', otp);
    fetch(`${BACKEND_AUTH_ENDPOINT}login`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 1) {
          messageAlert('success', 'Login successful');
          //   console.log('data', data);
          //   console.log('data', data.user.user_role_id);
          localStorage.setItem('token', data.authorisation.token);
          localStorage.setItem('user', JSON.stringify(data.user));

          if (data.user.user_role_id == 1 || data.user.user_role_id == 2) {
            //admin
            window.location.href = '/admin/dashboard';
          } else if (
            data.user.user_role_id == 3 ||
            data.user.user_role_id == 4
          ) {
            //user
            window.location.href = '/dashboard';
          } else if (data.user.user_role_id == 5) {
            //accountant
            window.location.href = '/accountant/dashboard';
          } else {
            //user
            window.location.href = '/dashboard';
          }
        } else {
          messageAlert('error', 'Invalid email or password');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const messageAlert = (type, msg) => {
    message[type](msg);
  };

  return (
    <StyledSign>
      <StyledSignContent>
        <StyledSignForm
          name='basic'
          initialValues={{
            remember: true,
            email: '',
            password: '',
            otp: '',
          }}
          onFinish={signInUserSubmit}
          onFinishFailed={onFinishFailed}
          className='sign-form'
        >
          <Form.Item
            name='email'
            className='form-field'
            rules={[{required: true, message: 'Please input your Email!'}]}
          >
            <Input
              placeholder={messages['common.email']}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
            />
          </Form.Item>

          <Form.Item
            name='password'
            className='form-field'
            rules={[{required: true, message: 'Please input your Password!'}]}
          >
            <Input.Password
              placeholder={messages['common.password']}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              value={password}
            />
          </Form.Item>

          <Form.Item
            className='form-field otp-field'
            rules={[{required: false, message: 'Please input your OTP'}]}
            style={{display: 'flex-inline'}}
          >
            <Input
              placeholder='OTP'
              title=''
              disabled={isOtpEnabled == 1 ? false : true}
              style={{width: '80%'}}
              onChange={(e) => {
                setOtp(e.target.value);
              }}
              value={otp}
            />

            <Button
              type='primary'
              loading={loadings[0]}
              onClick={sendOTP}
              disabled={isOtpButtonEnabled == 1 ? false : true}
            >
              Send OTP
            </Button>
          </Form.Item>

          <StyledRememberMe>
            <Checkbox onChange={onRememberMe}>
              <IntlMessages id='common.rememberMe' />
            </Checkbox>
            <StyledSignLink onClick={onGoToForgetPassword}>
              <IntlMessages id='common.forgetPassword' />
            </StyledSignLink>
          </StyledRememberMe>

          <div className='form-btn-field'>
            <SignInButton type='primary' htmlType='submit'>
              <IntlMessages id='common.login' />
            </SignInButton>
          </div>

          {/* <div className='form-field-action'>
          <StyledSignTextGrey>
            <IntlMessages id='common.dontHaveAccount' />
          </StyledSignTextGrey>
          <StyledSignLinkTag to='/signup'>
            <IntlMessages id='common.signup' />
          </StyledSignLinkTag>
        </div> */}
        </StyledSignForm>
      </StyledSignContent>
    </StyledSign>
  );
};

export default SignInCustom;
