export const getUserFromJwtAuth = (user) => {
  if (user) {
    // console.log('getUserFromJwtAuth user:', user);
    return {
      id: 1,
      uid: user._id,
      displayName: user.name,
      email: user.email,
      photoURL: user.avatar,
      // role: authRole.User,
      role: user.user_role,
      roleId: user.user_role_id,
      roleName: user.user_role_name,
    };
  }

  return user;
};
