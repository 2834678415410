import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  StyledAuth,
  StyledAuthCard,
  StyledAuthCardHeader,
  StyledAuthMainContent,
  StyledAuthWelContent,
  StyledAuthWellAction,
  StyledAuthWrap,
  StyledMainAuthScrollbar,
} from './AuthWrapper.styled';
// import AppLogo from '@crema/components/AppLayout/components/AppLogo';
import AppAnimateGroup from '@crema/components/AppAnimateGroup';
import AppInfoView from '@crema/components/AppInfoView';
import './index.scss';

const AuthWrapper = ({children}) => {
  const [indiaTime, setIndiaTime] = React.useState('');
  const [indiaDate, setIndiaDate] = React.useState('');
  const [indiaDayName, setIndiaDayName] = React.useState('');
  const [ksaTime, setKsaTime] = React.useState('');
  const [ksaDate, setKsaDate] = React.useState('');
  const [ksaDayName, setKsaDayName] = React.useState('');

  useEffect(() => {
    setInterval(showTime, 1000);
  }, [indiaTime, indiaDate, ksaTime, ksaDate]);

  function showTime() {
    // Getting current time and date
    let time = new Date();

    // Days array
    let days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];

    // Convert to India time (UTC+5:30)
    let indiaTime = new Date(time.getTime() + 5.5 * 60 * 60 * 1000);
    let indiaHour = indiaTime.getUTCHours();
    let indiaMin = indiaTime.getUTCMinutes();
    // let indiaSec = indiaTime.getUTCSeconds();
    let indiaAmPm = indiaHour >= 12 ? 'PM' : 'AM';
    indiaHour = indiaHour % 12;
    indiaHour = indiaHour ? indiaHour : 12; // the hour '0' should be '12'
    indiaHour = indiaHour < 10 ? '0' + indiaHour : indiaHour;
    indiaMin = indiaMin < 10 ? '0' + indiaMin : indiaMin;
    // indiaSec = indiaSec < 10 ? '0' + indiaSec : indiaSec;
    let indiaCurrentTime = indiaHour + ':' + indiaMin + ' ' + indiaAmPm;
    let indiaCurrentDate =
      ('0' + indiaTime.getUTCDate()).slice(-2) +
      '/' +
      ('0' + (indiaTime.getUTCMonth() + 1)).slice(-2) +
      '/' +
      indiaTime.getUTCFullYear();
    let indiaCurrentDayName = days[indiaTime.getUTCDay()];

    // Convert to KSA time (UTC+3)
    let ksaTime = new Date(time.getTime() + 3 * 60 * 60 * 1000);
    let ksaHour = ksaTime.getUTCHours();
    let ksaMin = ksaTime.getUTCMinutes();
    let ksaSec = ksaTime.getUTCSeconds();
    let ksaAmPm = ksaHour >= 12 ? 'PM' : 'AM';
    ksaHour = ksaHour % 12;
    ksaHour = ksaHour ? ksaHour : 12; // the hour '0' should be '12'
    ksaHour = ksaHour < 10 ? '0' + ksaHour : ksaHour;
    ksaMin = ksaMin < 10 ? '0' + ksaMin : ksaMin;
    ksaSec = ksaSec < 10 ? '0' + ksaSec : ksaSec;
    let ksaCurrentTime = ksaHour + ':' + ksaMin + ' ' + ksaAmPm;
    let ksaCurrentDate =
      ('0' + ksaTime.getUTCDate()).slice(-2) +
      '/' +
      ('0' + (ksaTime.getUTCMonth() + 1)).slice(-2) +
      '/' +
      ksaTime.getUTCFullYear();
    let ksaCurrentDayName = days[ksaTime.getUTCDay()];

    // Set the time, date, and day name
    setIndiaTime(indiaCurrentTime);
    setIndiaDate(indiaCurrentDate);
    setIndiaDayName(indiaCurrentDayName);
    setKsaTime(ksaCurrentTime);
    setKsaDate(ksaCurrentDate);
    setKsaDayName(ksaCurrentDayName);
  }

  return (
    <StyledAuth>
      <StyledMainAuthScrollbar>
        <AppAnimateGroup
          type='scale'
          animateStyle={{flex: '1'}}
          delay={0}
          style={{height: '100%'}}
          interval={10}
          duration={200}
        >
          <StyledAuthWrap key={'wrap'}>
            <StyledAuthCardHeader>
              <img
                src='/assets/images/logo-with-name.png'
                alt='dashboard-logo'
                className='login_logo'
              />
            </StyledAuthCardHeader>
            <StyledAuthCard>
              <StyledAuthMainContent>{children}</StyledAuthMainContent>

              <StyledAuthWellAction>
                <div className='main_right'>
                  {/* <StyledAuthWelContent> */}
                  {/* <StyledAuthCardHeader>
                    <img
                      src='/assets/images/logo-with-name.png'
                      alt='dashboard-logo'
                      className='login_logo'
                    />
                  </StyledAuthCardHeader> */}

                  <div className='time_wrapper'>
                    <div className='left'>
                      <StyledAuthCardHeader>
                        <img
                          src='/assets/images/ind.png'
                          alt='dashboard-logo'
                          className='flag_img'
                        />
                      </StyledAuthCardHeader>
                      <div className='country_name'> India </div>
                      <div className='clock_wrapper'>
                        <div className='time'>{indiaTime}</div>
                        <div className='date'>{indiaDate}</div>
                        <div className='day'>{indiaDayName}</div>
                      </div>
                    </div>
                    <div className='right'>
                      <StyledAuthCardHeader>
                        <img
                          src='/assets/images/sa.png'
                          alt='dashboard-logo'
                          className='flag_img'
                        />
                      </StyledAuthCardHeader>
                      <div className='country_name'> Arabia</div>
                      <div className='clock_wrapper'>
                        <div className='time'>{ksaTime}</div>
                        <div className='date'>{ksaDate}</div>
                        <div className='day'>{ksaDayName}</div>
                      </div>
                    </div>
                  </div>
                  {/* </StyledAuthWelContent> */}
                </div>
              </StyledAuthWellAction>
            </StyledAuthCard>
          </StyledAuthWrap>
          <AppInfoView />
        </AppAnimateGroup>
      </StyledMainAuthScrollbar>
    </StyledAuth>
  );
};

export default AuthWrapper;

AuthWrapper.propTypes = {
  children: PropTypes.node,
};
